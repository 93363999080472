// extracted by mini-css-extract-plugin
export var alternate = "Button-module--alternate--a8208";
export var button = "Button-module--button--c17ef";
export var disabled = "Button-module--disabled--9c987";
export var flat = "Button-module--flat--2c247";
export var fullWidth = "Button-module--fullWidth--737a0";
export var large = "Button-module--large--e508c";
export var link = "Button-module--link--c7764";
export var primary = "Button-module--primary--2e17d";
export var secondary = "Button-module--secondary--e788d";
export var small = "Button-module--small--c0339";
export var smallest = "Button-module--smallest--fe15c";
export var tertiary = "Button-module--tertiary--c503d";